import React from 'react';
import Website from '../layouts/website';
import { shallowEqual, useSelector } from 'react-redux';
import Layout from '../layouts';

export default function Error404() {
  const { user } = useSelector(
    (state) => ({
      user: state.user,
    }),
    shallowEqual
  );

  const Page = user && user.id ? Layout : Website;

  return (
    <Page title="404" mod="Overview">
      <div className="text-center mt-10 mb-20">
        {/*<h1>Page Not Found</h1>*/}
        <img
          src="/images/illustration/404.png"
          style={{ maxWidth: '500px', width: '80%' }}
          className="m-auto mt-10"
        />
      </div>
    </Page>
  );
}
